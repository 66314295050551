import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Placeholder,
} from 'semantic-ui-react';
import { compose } from 'redux';
import { Helmet } from '@plone/volto/helpers';

import { getUser } from '@plone/volto/actions';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

// A simple component that shows the pathname of the current location

// portrait api: /++api++/@portrait/admin

async function getUserPortrait(userId) {
  try {
    const url = `/++api++/@portrait/${userId}`;
    const data = await fetch(url);
  } catch (e) {
    return { data: null, error: e.toString() };
  }
}

const AuthorView = (props) => {
  //const [savedUser, setSavedUser] = React.useState()
  const theState = useSelector((state) => state);
  const user = useSelector((state) => state.users.user);
  const { author_id } = useParams();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUser(author_id));
    //const fromOnline = await dispatch(getUser(author_id));
    //setSavedUser(fromOnline);
  }, [dispatch]);
  console.log(user);

  // React.useEffect(() => {
  //   console.log('user loaded...fetch image');
  //   if (user) {
  //     getUserPortrait(user.userId)
  //       .then((res) => {})
  //       .catch((e) => {
  //         console.error('e: ', e);
  //       });
  //   }
  // }, [user]);

  return (
    <>
      <Helmet title={user && user.fullname} />

      <Container className="view-wrapper">
        {user?.id ? (
          <div>
            <Header size="huge" as="h1">
              {user && user.fullname}
            </Header>
            <hr />
            {/*{!user.email_private && user.email}*/}
            {/*<img src={user.portrait} />
      {user.portrait}*/}
            {/* Card sections */}

            <Grid padded>
              <Grid.Row>
                <Grid.Column width={3}>
                  {!user ? (
                    <Placeholder fluid>
                      <Placeholder.Image square />
                    </Placeholder>
                  ) : (
                    !user.portrait ? (

                      <div>No portrait</div>
                    ): (
                    <Image src={`/++api++/@portrait/${user.id}`} alt={user.portrait} size="medium" />
                    )
                  )}
                </Grid.Column>
                <Grid.Column width={9}>
                  {/*<Label.Group>*/}
                  {/*  {user.practice_area &&*/}
                  {/*    user.practice_area.map(function (practice_area, index) {*/}
                  {/*      // we're using a red that is overridden in theme/globals/site.variables*/}
                  {/*      // this is the texas brand red (@texasRed) and we want to eventually be able to say*/}
                  {/*      // <Label color='texasRed' key={ index }>*/}
                  {/*      return (*/}
                  {/*        <Label className="" color="red" key={index}>*/}
                  {/*          {practice_area}*/}
                  {/*        </Label>*/}
                  {/*      );*/}
                  {/*    })}*/}
                  {/*</Label.Group>*/}
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Contact Information</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      {!user.email_private ? (
                        <p>
                          <Icon name="mail" />
                          {user.email}
                        </p>
                      ) : (
                        'N/A'
                      )}
                    </Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Phone Numbers</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      <Label color="red">
                        Mobile:
                        <Label.Detail>{user.cell ?? 'N/A'}</Label.Detail>
                      </Label>
                      {!user.phone_private && (
                        <Label color="red">
                          Phone:{' '}
                          <Label.Detail>{user.phone ?? 'N/A'}</Label.Detail>
                        </Label>
                      )}
                    </Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Online Presence</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      {user.facebook ? (
                        <Label>
                          <Icon name="facebook" />
                          {user.facebook}
                        </Label>
                      ) : undefined}
                      {user.linkedin ? (
                        <Label>
                          <Icon name="linkedin square" />
                          {user.linkedin}
                        </Label>
                      ) : undefined}
                      {user.home_page ? (
                        <Label>
                          <Icon name="linkify" />
                          {user.home_page}
                        </Label>
                      ) : undefined}
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Card fluid>
                    <Card.Content>{user.description}</Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>
                        Practice Areas/Associated Skills (tags)
                      </Card.Header>
                    </Card.Content>
                    <Card.Content>
                      {user?.practice_area?.length
                        ? user.practice_area.map((area) => (
                            <Label key={area}>{area}</Label>
                          ))
                        : 'Not Listed'}
                    </Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Representative Transactions</Card.Header>
                    </Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Opt-in/Opt-out Preferences</Card.Header>
                    </Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Articles & Publications</Card.Header>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div>Nothing to see here</div>
        )}
      </Container>
    </>
  );
};

export default AuthorView;
// compose(
//   withRouter,
//   injectIntl,
//   connect(
//     (state, props) => ({
//       loading: state.users.initial.loading,
//       loaded: state.users.initial.loaded,
//       error: state.users.initial.error,
//       id: props.match.params.author_id,
//     }),
//     { getUser },
//   ),
// )(AuthorView);
