import React from "react";
import { Container, Grid, Header, Item, Label, Segment } from 'semantic-ui-react';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Committees } from '@package/components/';
import { Link } from 'react-router-dom';

const CommitteeListing = (content) => {
    const committees = useSelector(
        (state) => state.search.subrequests.committees?.items,
    );
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchFilter, setSearchFilter] = React.useState([]);
    const [searchResults, setSearchResults] = React.useState([]);
    const dispatch = useDispatch();
    const searchHandler = (searchTerm,searchFilter) => {
         setSearchTerm(searchTerm);
        setSearchFilter(searchFilter);

       // return committees that match the tags (searchFilter)
       // that we have selected
       // XXX Bug no committees show if searchFilter is blank
       // All committees should show if searchFilter is blank
        const preFilteredCommittees = searchFilter.length > 0 ? committees.filter(committee => {
                return committee.subjects.some(i => searchFilter.includes(i));
        }) : committees;
        
        if (searchTerm !== "") {
            // prefilter the committees with the dropdown values
            // then use preFilteredCommittees for everything below instead of 
            // "unfiltered" committees
            // or maybe they are independent of each other  
            const searchFilteredCommittees = preFilteredCommittees.filter((committee) => {
                return Object.values(committee)
                .join(" ")
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            })
          setSearchResults(searchFilteredCommittees);
        } else {
            setSearchResults(preFilteredCommittees)
        }
     
    };

    React.useEffect(() => {
        dispatch(
            searchContent(
                '/committees',
                {
                    portal_type: ['committee'],
                    fullobjects: true,
                    review_state: 'open',
                },
                'committees',
            ),
        );
    }, [dispatch]);
    return (
        <Container className="view-wrapper">
            {/*<Segment className="general-commmittees">
                <Header as="h2">General Interest Committees</Header>
                <Grid>
                    <Grid.Row columns={2}>
                            {committees && committees.filter(committee => committee.subjects.includes('General Interest Committee')).map((filteredCommittee,index) => (
                                <Grid.Column key={index}>
                                    <Item.Group relaxed>
                                    <Item>
                                        <Item.Content verticalAlign='middle'>
                                            <Item.Header as='a' href={filteredCommittee['@id']}>
                                                {filteredCommittee.title}
                                            </Item.Header>
                                            <Item.Description>
                                            {filteredCommittee.description}
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                </Item.Group>
                                </Grid.Column>
                            ))}
                    </Grid.Row>
                </Grid>
            </Segment> */}
            {/* Below here is all the committees */}
            <section id="content-core">
             {committees && <Committees 
              {...content}
              committeesOrig={committees}
              committees={searchTerm.length < 1 ? committees : searchResults}
              term={searchTerm} 
              searchValues={searchHandler} 
              />
             }
            </section>
        </Container>
    );
};

export default CommitteeListing;