import React from 'react'

import PropTypes from 'prop-types'
import CTABanner from './CTABanner';

import './component.css'



const View = (props) => {
  return (
    <CTABanner props={props} />
  )
};

export default View;
