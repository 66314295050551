/**
 * View ResourceSearch block.
 * @module components/Blocks/ResourceSearch/View
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

const MEMBER_BENEFITS = [
  {
    title: 'The Texas Journal of Business Law',
    description:
      '<p>Long form articles on issues in the area of business law, edited by student editors and faculty at South Texas College of Law, Houston.</p>',
    link:
      'https://www.texasbusinesslaw.org/resources/texas-business-law-journal',
    linkTitle: 'Visit the Journal',
    images: [
      '../images/wesley-tingey-9z9fxr_7Z-k-unsplash.jpeg',
      '../images/tingey-injury-law-firm-NcNqTsq-UVY-unsplash.jpeg',
    ],
  },
  {
    title: 'CLE Program Materials',
    description:
      '<p>Materials created by presenters at CLE programs put on by Texas Bar CLE and UT Law CLE and co-sponsored by the Section.</p>' +
      '<p>Find presentation slides, papers and webcasts from those programs.</p>',
    link: 'https://www.texasbusinesslaw.org/resources/cle-materials',
    linkTitle: 'View CLE Materials',
    images: [
      '../images/young-businessman-cheering-during-seminar-in-front-EDAYWFX.jpeg',
      '../images/businesswoman-using-laptop-in-car-C9MPK8G.jpeg',
    ],
  },
  {
    title: 'Legislation',
    description:
      '<p>The Section partners with the Texas Business Law Foundation to monitor each biennial legislative session of the Texas Legislature.  A weekly report provides updates on new bills during the session.</p>',
    link: 'https://www.texasbusinesslaw.org/resources/leglslative-reports',
    linkTitle: 'View Legislative Reports',
    images: [
      '../images/architecture-BEATRWF.jpeg',
      '../images/judge-gavel-with-old-books-PFMSKUA.jpeg',
    ],
  },
  {
    title: 'Annotated Forms',
    description:
      '<p>The “Business Organization Forms”, “Partnerships and LLCs” and “Contracts” Committees of the Section create governing documents and other contracts for use in different situations with annotated commentary.</p>',
    link: 'https://www.texasbusinesslaw.org/resources/forms',
    linkTitle: 'Get Forms',
    images: [
      '../images/business-contract-9QT7V28.jpeg',
      '../images/contract-on-wooden-table-M469JFV.jpeg',
    ],
  },
  {
    title: 'Special Offers',
    description:
      '<p>The Section chooses certain vendors and organizations to work with to provide special offers regarding products, services or professional events.</p>',
    link: 'https://www.texasbusinesslaw.org/resources/special-offers',
    linkTitle: 'View Offers',
    images: [
      '../images/special-offers.jpeg',
      '../images/special-offer-concierge.jpeg',
    ],
  },
];

/**
 * View VerticalTabMenu block
 */
const View = ({ data, path }) => {
  const [activeBenefitIndex, setActiveBenefitIndex] = useState(0);

  const activeBenefit = useMemo(() => {
    const benefit = MEMBER_BENEFITS[activeBenefitIndex];
    return benefit || undefined;
  }, [activeBenefitIndex]);

  // return <div className="block vertical-tab-menu">I am the tabbed menu</div>;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <div
            style={{
              backgroundColor: '#5e5e5e',
              padding: '2rem',
            }}
          >
            <Header as="h2" style={{ color: '#fff' }}>
              Member Benefits
            </Header>
            {MEMBER_BENEFITS.map((benefit, index) => (
              <Header
                as="h4"
                key={benefit['title']}
                style={{
                  borderBottom:
                    activeBenefitIndex === index ? '3px solid #56ccf2' : 'none',
                  color: '#fff',
                  cursor: 'pointer',
                  paddingBottom: '1.5rem',
                }}
              >
                <div onClick={() => setActiveBenefitIndex(index)}>
                  {benefit['title']}
                </div>
              </Header>
            ))}
          </div>
        </Grid.Column>
        <Grid.Column width={5}>
          <div
            style={{
              paddingTop: '1.65rem',
            }}
          >
            {activeBenefit ? (
              <>
                <Header
                  as="h2"
                  style={{
                    borderBottom: 'solid 2px #ce1f22',
                    paddingBottom: '1rem',
                  }}
                >
                  {activeBenefit['title']}
                </Header>
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeBenefit['description'],
                  }}
                  style={{
                    fontSize: '1.25rem',
                    marginBottom: '3rem',
                  }}
                />
                <div>
                  <a
                    href={activeBenefit['link']}
                    style={{
                      border: 'solid 1px #ce1f22',
                      borderRadius: '0.5rem',
                      color: '#333',
                      fontSize: '1.25rem',
                      marginTop: '1rem',
                      padding: '1.5rem',
                    }}
                  >
                    {activeBenefit['linkTitle']}
                  </a>
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={3}></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

// from the API, fetch 'resources' to get 'Member benefits'
// @search?resources
// path.query=%2Fresources%2Flegislation&portal_type=Folder&path.depth=1&fullobjects=1

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default React.memo(View);
