import React from 'react';
import { FormFieldWrapper } from '@plone/volto/components';
import { SidebarPortal } from '@plone/volto/components';

const CommitteesSidebar = (props) => {
  const { data, block, onChangeBlock } = props;

  const handleChange = (field) => (event) => {
    onChangeBlock(block, {
      ...data,
      [field]: event.target.value,
    });
  };

  return (
    <SidebarPortal selected={props.selected}>
      <div>
        <h3>Committees Sidebar</h3>
        <FormFieldWrapper id="title" title="Block title" description="Title of the block">
          <input
            type="text"
            value={data.title || ''}
            onChange={handleChange('title')}
            placeholder="Block title"
          />
        </FormFieldWrapper>
      </div>
    </SidebarPortal>
  );
};

export default CommitteesSidebar;
