import React from 'react';
import Committees from './Committees';
import { SidebarPortal } from '@plone/volto/components';
import CommitteesSidebar from './CommitteesSidebar';

const CommitteesEdit = (props) => {
  const { data, selected, onChangeBlock, block } = props;

  return (
    <>
      <Committees {...props} />
      <SidebarPortal selected={selected}>
        <CommitteesSidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default CommitteesEdit;
