/**
 * @description Periodic Element Header Component
 */
import React, { useEffect, useState } from 'react';
import { Placeholder } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './pheStyles.css';

const PeriodicHeaderElement = ({ data }) => {
  const [loadingRecentItems, setLoadingRecentItems] = useState(false);
  const [recentItems, setRecentItems] = useState([]);

  let imagePath;
  const { elementImage, relatedFolder } = data;

  useEffect(() => {
    const fetchRecent = async () => {
      try {
        setLoadingRecentItems(true);
        const searchParams = new URLSearchParams({
          portal_type: 'Document',
          sort_on: 'modified',
          sort_order: 'descending',
          b_size: 5,
        });

        const url = `++api++${
          relatedFolder[0]['@id']
        }?${searchParams.toString()}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        const { items } = jsonData;
        if (Array.isArray(items)) {
          const _items = items.map((item, index) => ({
            id: index,
            title: item['title'],
            link: item['@id'],
          }));
          setRecentItems(_items);
          setLoadingRecentItems(false);
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoadingRecentItems(false);
      }
    };

    fetchRecent().then();
  }, [relatedFolder]);

  if (elementImage) {
    imagePath = elementImage['getURL'];
  }
  return (
    <div>
      <h3 className="pph-heading">
        {data.header ? data.header : '[No Title]'}
      </h3>
      <div className="header-container">
        {imagePath ? (
          <div>
            <img src={imagePath} alt="" />
          </div>
        ) : (
          <div className="element-card">
            <span className="pt-element pt-element-text">
              {data.elementText ? data.elementText : 'N/A'}
            </span>
            <br />
            <span className="pt-element pt-element-subtext">
              {data.elementSubText ? data.elementSubText : ' N/A'}
            </span>
          </div>
        )}
        <div className="latest">
          <h4>Latest</h4>
          <div>
            {loadingRecentItems ? (
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            ) : (
              recentItems.map((item, index) => (
                <div key={`${item.id}-${index}`}>
                  <a href={item.link}>{item.title}</a>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PeriodicHeaderElement.PropTypes = {
  data: PropTypes.object,
};

export default React.memo(PeriodicHeaderElement);
