/**
 * @description Create
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Card, Grid} from 'semantic-ui-react';

const GridListTemplate = ({items}) => {
  return (
    <Grid columns={2}>
      {items.map((item, index) => {
        console.log(item);
        return (
          <Grid.Column key={`item-index-${index}`}>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <h4>{item['Type'] || item['@type']}</h4>
                  <a title={item.title} href={item.getURL}>{item.title}</a>
                </Card.Header>
                <Card.Description>
                    {item.description}
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

GridListTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default GridListTemplate;
