/**
 * Footer component (Override)
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Image, Container, Grid, List, Segment, Button } from 'semantic-ui-react';
// import { map } from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
// import { UniversalLink } from '@plone/volto/components';
// import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import StateBuilding from './statebuilding-watermark.svg';
import './footer.css';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = () => 
{
  return (
    <footer className="footer-wrapper">
    <div className="footer-container w-container">
      <div className="footer-cta">
        <div className="w-layout-grid footer-cta-grid">
          <div className="footer-cta-left">
            <div className="cta-left-content-wrap">
              <div>
                <div className="x---color-neutral-100">
                  <div className="footer-cta-header">We're Here to Help. Get in Touch.</div>
                </div>
              </div>
              <div>
                <div className="footer-cta-text">Whether you need guidance on an emerging legal issue, want to learn more about member benefits, or have general inquiries about the Business Law Section - we're here to help.</div>
              </div>
            </div>
          </div>
          <div 
          className="footer-cta-button-row">
            <Button href="/contact-form" className="cta-button w-button">Contact Us</Button>
          </div>
        </div>
      </div>
      <div className="footer-top-wrap">
        <div className="w-layout-grid footer-main">
          <div id="w-node-_2eeb26fd-39fd-f3d8-decc-8aa29cca315f-36009155" className="footer-info-box">
            <div>
              <a href="#" className="footer-logo-wrap w-inline-block">
                   <img src="https://cdn.prod.website-files.com/5943eb0ca703d11d6a9e30bc/5bca202034010056bfb8e6b4_texasbusinesslawlogo.png" alt="" sizes="(max-width: 479px) 83vw, 182px" srcSet="https://cdn.prod.website-files.com/5943eb0ca703d11d6a9e30bc/5bca202034010056bfb8e6b4_texasbusinesslawlogo-p-500.png 500w, https://cdn.prod.website-files.com/5943eb0ca703d11d6a9e30bc/5bca202034010056bfb8e6b4_texasbusinesslawlogo.png 566w" className="footer-logo" />
              </a>
            </div>
            <div className="x---mg-bottom-40px">
              <div>
                <p className="txbl-footer-feature-note">The Business Law Section of the State Bar of Texas provides resources in the fields of corporate, securities, commercial, banking and bankruptcy law for attorneys in the State of Texas.</p>
              </div>
            </div>
          </div>
          <div>
            <div className="txbl-footer-resource-header">Membership Benefits</div>
            <ul role="list" className="footer-list-wrap">
              <li className="footer-list-item"><a href="/resources/webinars" className="txbl-footer-resource-link">Webinars &amp; Podcasts</a></li>
              <li className="footer-list-item"><a href="/resources/legislation" className="txbl-footer-resource-link">Legislation</a></li>
              <li className="footer-list-item"><a href="/resources/newsletters" className="txbl-footer-resource-link">Newsletters</a></li>
              <li className="footer-list-item"><a href="/resources/texas-business-law-journal" className="txbl-footer-resource-link">Texas Journal of Business Law</a></li>
              <li className="footer-list-item"><a href="/resources/cle-materials" className="txbl-footer-resource-link">CLE Materials</a></li>
            </ul>
          </div>
          <div>
            <div className="txbl-footer-resource-header">Resource Centers</div>
            <ul role="list" className="footer-list-wrap">
              <li className="footer-list-item"><a href="/resource-centers/ai" className="txbl-footer-resource-link">Artificial Intelligence</a></li>
              <li className="footer-list-item"><a href="/resource-centers/business-drafting" className="txbl-footer-resource-link">Business Drafting</a></li>
              <li className="footer-list-item"><a href="/resource-centers/securities-law" className="txbl-footer-resource-link">Securities Law</a></li>
              <li className="footer-list-item"><a href="/resource-centers/practice-tips-tools" className="txbl-footer-resource-link">Practice Tips &amp; Tools</a></li>
              <li className="footer-list-item"><a href="/resource-centers/legal-opinions" className="txbl-footer-resource-link">Legal Opinions</a></li>
              <li className="footer-list-item"><a href="/resource-centers/business-courts" className="txbl-footer-resource-link">Business Courts</a></li>
            </ul>
          </div>
          <div>
            <div className="txbl-footer-resource-header">Outside Resources</div>
            <ul role="list" className="footer-list-wrap">
              <li className="footer-list-item"><a href="https://www.texasbar.com/" className="txbl-footer-resource-link">State Bar of Texas</a></li>
              <li className="footer-list-item"><a href="http://www.texasbarcle.com/" className="txbl-footer-resource-link">Texas Bar CLE</a></li>
              <li className="footer-list-item"><a href="https://utcle.org/" className="txbl-footer-resource-link">UT Law CLE</a></li>
            </ul>
          </div>
        </div>
        <Image src={StateBuilding} className="footer-watermark" loading="lazy" alt="" />
      </div>
      <div className="footer-bottom">
        <div className="w-layout-hflex footer-bottom-links">
          <a href="#" className="txbl-footer-resource-link">Terms and Conditions</a>
          <a href="#" className="txbl-footer-resource-link">Privacy Policy</a>
          <a href="#" className="txbl-footer-resource-link">Site Map</a>
        </div>
      </div>
    </div>
  </footer>
  );
};

/**
* Property types.
* @property {
  Object
} propTypes Property types.
* @static
*/
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default Footer;
