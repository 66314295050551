/**
 * @description Contains sidebar portal form when the component is in Edit mode
 */
import React from 'react';
import { BlockDataForm } from '@plone/volto/components';
import { DataSchema } from './Schema';

const Data = (props) => {
  const { block, data, onChangeBlock } = props;
  const schema = DataSchema(props);
  return (
    <div>
      <h2>Configure Periodic Page Header</h2>
      <BlockDataForm
        schema={schema}
        title={schema.title}
        onChangeField={(id, value) => {
          onChangeBlock(block, {
            ...data,
            [id]: value,
          });
        }}
        formData={data}
        block={block}
      />
    </div>
  );
};

export default Data;
