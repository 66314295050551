/**
 * @description Schema definition for edit view of Periodic Page Header
 */
export const DataSchema = (props) => {
  return {
    title: 'Periodic Page Header',
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: [
          'header',
          'elementText',
          'elementSubText',
          'elementImage',
          'relatedFolder',
        ],
      },
    ],
    properties: {
      header: {
        title: 'Page Title',
      },
      elementText: {
        title: 'Element Text Abbreviation',
      },
      elementSubText: {
        title: 'Element Sub Text',
      },
      elementImage: {
        title: 'Element Image',
        description:
          'The image that should be used instead of text for the periodic table element',
        widget: 'object_browser',
        mode: 'image',
        return: 'single',
      },
      relatedFolder: {
        title: 'Related Folder',
        description:
          'The folder that will have the most recent items displayed for. You can use the search field to filter resources',
        widget: 'object_browser',
        mode: 'tree_select',
      },
    },
    required: [],
  };
};
