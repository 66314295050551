/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import { CommitteeView } from './components';
import CommitteeListing from './components/Views/CommitteeListing';

// import custom blocks
import VerticalTabMenuView from './components/Blocks/VerticalTabMenu/View';
import VerticalTabMenuEdit from './components/Blocks/VerticalTabMenu/Edit';
import icon from '@plone/volto/icons/list-bullet.svg';
import PeriodicPageHeaderView from './components/Blocks/PeriodicPageHeader/View';
import PeriodicPageHeaderEdit from './components/Blocks/PeriodicPageHeader/Edit';
import TXBizLawCTAView from './components/Blocks/TXBizLawCTA/View';
import TXBizLawCTAEdit from './components/Blocks/TXBizLawCTA/Edit';
import SimpleSearchEdit from './components/Blocks/SimpleSearch/Search';
import SimpleSearchView from './components/Blocks/SimpleSearch/Search';

import CommitteeListTemplate from './components/Blocks/ListingVarations/CommitteeListTemplate/CommitteeListTemplate';
import GridListTemplate from './components/Blocks/ListingVarations/GridListTemplate/GridListTemplate';
import ResourceGridTemplate from './components/Blocks/ListingVarations/ResourceGridTemplate/ResourceGridTemplate';
import GatedContentListTemplate from './components/Blocks/ListingVarations/GatedContentListTemplate/GatedContentListTemplate';
import PersonalUrlsWidget from './components/Widgets/PersonalUrlsWidget';
import CommitteesView from './components/Blocks/Committees/Committees';
import CommitteesEdit from './components/Blocks/Committees/CommitteesEdit';
import AskAnythingBlock from './components/Blocks/AskAnythingBlock/AskAnythingBlock';


export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  /*




  */

  config.blocks = {
    ...config.blocks,
    blocksConfig: {
      ...config.blocks.blocksConfig,
      verticalTabMenu: {
        id: 'verticalTabMenu',
        title: 'Vertical Tab Menu',
        edit: VerticalTabMenuEdit,
        view: VerticalTabMenuView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
          addPermission: [],
          view: [],
        },
      },
      askanythingblock: {
        id: 'askanythingblock',
        title: 'Ask Anything Block',
        edit: AskAnythingBlock,
        view: AskAnythingBlock,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
      },
      committees: {
        id: 'committees',
        title: 'Committees',
        icon: 'group',
        group: 'common',
        edit: CommitteesEdit,
        view: CommitteesView,
        restricted: false,
        mostUsed: true,
        blockHasOwnFocusManagement: true,
      },
      periodicPageHeader: {
        id: 'periodicPageHeader',
        title: 'Periodic Page Header',
        edit: PeriodicPageHeaderEdit,
        view: PeriodicPageHeaderView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
      },
      txBizLawCTA: {
        id: 'txBizLawCTA',
        title: 'TXBizLaw CTA',
        edit: TXBizLawCTAEdit,
        view: TXBizLawCTAView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
      },
      simpleSearchBlock: {
        id: 'simpleSearchBlock',
        title: 'Simple Search Block',
        edit: SimpleSearchEdit,
        view: SimpleSearchView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
      },
    },
  };

  config.blocks.blocksConfig.listing.variations.push({
    id: 'gridListTemplate',
    title: 'GridList',
    template: GridListTemplate,
  });
  config.blocks.blocksConfig.listing.variations.push({
    id: 'committeeListTemplate',
    title: 'CommitteeList',
    template: CommitteeListTemplate,
  });
  config.blocks.blocksConfig.listing.variations.push({
    id: 'gatedContentistTemplate',
    title: 'Gated Content List',
    template: GatedContentListTemplate,
  });
  config.blocks.blocksConfig.listing.variations.push({
    id: 'resourceGridTemplate',
    title: 'Resource Grid',
    template: ResourceGridTemplate,
  });


  // Custom Views
  config.views.contentTypesViews.committee = CommitteeView;
  config.views.layoutViews.committee_listing = CommitteeListing;
  config.widgets.id.personal_urls = PersonalUrlsWidget;
  console.log(config);

  return config;
}
