import React from 'react'

import PropTypes from 'prop-types'
import { Image, Button } from 'semantic-ui-react'; 
import './askanything.css'
import AskAnythingImage from './askanythingimage.png'; 

const Askanythingwrap = (props) => {
  return (
    <div className="askanythingwrap-askanythingwrap">
      <div className="askanythingwrap-askanythingcontent">
        <div className="askanythingwrap-frame56">
          <span className="askanythingwrap-text">
            <span>Ask Anything</span>
          </span>
          <span className="askanythingwrap-text02">
            <span>
              A small committee of section members who practice in this area,
              take turns answering questions from members of the business law
              section.
            </span>
          </span>
        </div>
        <div className="askanythingwrap-group">
          <Button className="askanythingwrap-askbutton">
             Click to Ask
          </Button>
          <Button className="askanythingwrap-joinbutton">
              Join the Section*
          </Button>
        </div>
        <span className="askanythingwrap-text08">
          <span>* it only costs $30 per year to join</span>
        </span>
      </div>
      <Image src={AskAnythingImage} alt="Ask Anything Image" 
        className="askanythingwrap-image"
        />  

    </div>
  )
}


export default Askanythingwrap
