import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Input,
  Segment,
  Grid,
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import qs from 'query-string';
import './login.css';

import { Helmet } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { Icon } from '@plone/volto/components';
import { login, resetLoginRequest } from '@plone/volto/actions';
import { toast } from 'react-toastify';
import { Toast } from '@plone/volto/components';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  login: {
    id: 'Log in',
    defaultMessage: 'Log in',
  },
  loginName: {
    id: 'Login Name',
    defaultMessage: 'Login Name (Texas Bar Number)',
  },
  Login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  password: {
    id: 'Password',
    defaultMessage: 'Password',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  loginFailed: {
    id: 'Login Failed',
    defaultMessage: 'Login Failed',
  },
  loginFailedContent: {
    id: 'Both email address and password are case sensitive, check that caps lock is not enabled.',
    defaultMessage:
      'Both email address and password are case sensitive, check that caps lock is not enabled.',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  forgotPassword: {
    id: 'box_forgot_password_option',
    defaultMessage: 'Forgot your password?',
  },
});

const Login = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const error = useSelector((state) => state.userSession.login.error);
  const loading = useSelector((state) => state.userSession.login.loading);
  const returnUrl =
    qs.parse(props.location?.search ?? location.search).return_url ||
    location.pathname.replace(/\/login\/?$/, '').replace(/\/logout\/?$/, '') ||
    '/';

  useEffect(() => {
    if (token && !(props.isLogout || location?.state?.isLogout)) {
      history.push(returnUrl || '/');
      if (toast.isActive('loggedOut')) {
        toast.dismiss('loggedOut');
      }
      if (toast.isActive('loginFailed')) {
        toast.dismiss('loginFailed');
      }
    }
    if (error) {
      if (toast.isActive('loggedOut')) {
        toast.dismiss('loggedOut');
      }
      if (!toast.isActive('loginFailed')) {
        toast.error(
          <Toast
            error
            title={intl.formatMessage(messages.loginFailed)}
            content={intl.formatMessage(messages.loginFailedContent)}
          />,
          { autoClose: false, toastId: 'loginFailed' },
        );
      }
    }
    return () => {
      if (toast.isActive('loginFailed')) {
        toast.dismiss('loginFailed');
        dispatch(resetLoginRequest());
      }
    };
  }, [
    dispatch,
    token,
    error,
    intl,
    history,
    returnUrl,
    props.isLogout,
    location?.state?.isLogout,
  ]);

  const onLogin = (event) => {
    dispatch(
      login(
        document.getElementsByName('login')[0].value,
        document.getElementsByName('password')[0].value,
      ),
    );
    event.preventDefault();
  };

  return (
    <div id="page-login">
      <Helmet title={intl.formatMessage(messages.Login)} />
      <div className="login-view-wrapper">
        <div className="login-view-instructions" id="challenge-text">
          <h2>Login to Access</h2>
          <h3>If you are a member of the Business Law Section</h3>
          <ul>
            <li>You can log in to the website on this page</li>
            <li>
              Use your Texas Bar No and the password you use to log in to
              texasbar.com
            </li>
          </ul>
          <h3>
            If you are an attorney licensed in Texas but not a member of the
            Section
          </h3>
          <ul>
            <li>You can join the Business Law Section for only $30 per year</li>
            <li>
              <a href="http://www.texasbar.com/">Sign up</a> as you do for other
              sections of the Bar on your My Bar Page at Texasbar.com
            </li>
            <li>
              Then log in on this page or at the "Login" logo at the top right
              of any page on the website.
            </li>
          </ul>
          <h3>If you are an attorney not licensed in Texas or a non-lawyer</h3>
          <ul>
            <li>
              Some of our content is available to all visitors (TX Journal of
              Business Law, Legal Opinions materials)
            </li>
            <li>
              We are working on a solution that would give you access to the
              site on a day-pass basis
            </li>
            <li>
              Leave us your contact information on the Contact Form in the
              footer of the site
            </li>
            <li>
              We will let you know when we have eCommerce capabilities up and
              running
            </li>
          </ul>
        </div>

        <Container text>
          <Form method="post" onSubmit={onLogin}>
            <Segment.Group raised className="tx-login-form">
              <Segment className="primary tx-login-form-header">
                <FormattedMessage id="Log In" defaultMessage="Login" />
              </Segment>
              {/*<Segment secondary>
                <FormattedMessage
                id="Sign in to start session"
                defaultMessage="Sign in to start session"
              />
              </Segment>
              */}
              <Segment className="form">
                <Form.Field inline className="help">
                  <Grid>
                    <Grid.Row stretched>
                      <Grid.Column width="4">
                        <div className="wrapper">
                          <label htmlFor="login">
                            <FormattedMessage
                              id="Login Name"
                              name="login"
                              defaultMessage="Login Name (Texas Bar Number)"
                            />
                          </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column width="8">
                        {/* eslint-disable jsx-a11y/no-autofocus */}
                        <Input
                          id="login"
                          name="login"
                          placeholder={intl.formatMessage(messages.loginName)}
                          autoFocus
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
                <Form.Field inline className="help">
                  <Grid>
                    <Grid.Row stretched>
                      <Grid.Column stretched width="4">
                        <div className="wrapper">
                          <label htmlFor="password">
                            <FormattedMessage
                              id="Password"
                              defaultMessage="Password"
                            />
                          </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column stretched width="8">
                        <Input
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          name="password"
                          placeholder={intl.formatMessage(messages.password)}
                          tabIndex={0}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
                <Form.Field inline className="help">
                  <Grid>
                    <Grid.Row stretched>
                      {config.settings.showSelfRegistration && (
                        <Grid.Column stretched width="12">
                          <p className="help">
                            <Link to="/register">
                              {intl.formatMessage(messages.register)}
                            </Link>
                          </p>
                        </Grid.Column>
                      )}
                      <Grid.Column stretched width="12">
                        <p className="help">
                          <Link to="/passwordreset">
                            {intl.formatMessage(messages.forgotPassword)}
                          </Link>
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Segment>
              <Segment className="actions" clearing>
                <Button
                  basic
                  primary
                  icon
                  floated="right"
                  type="submit"
                  id="login-form-submit"
                  aria-label={intl.formatMessage(messages.login)}
                  title={intl.formatMessage(messages.login)}
                  loading={loading}
                >
                  <Icon className="circled" name={aheadSVG} size="30px" />
                </Button>

                <Button
                  basic
                  secondary
                  icon
                  floated="right"
                  id="login-form-cancel"
                  as={Link}
                  to="/"
                  aria-label={intl.formatMessage(messages.cancel)}
                  title={intl.formatMessage(messages.cancel)}
                >
                  <Icon className="circled" name={clearSVG} size="30px" />
                </Button>
              </Segment>
            </Segment.Group>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default Login;
