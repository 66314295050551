import { Helmet } from '@plone/volto/helpers';
import { Card, Container, Grid, Header, Icon, Item } from 'semantic-ui-react';
import CommitteeChair from '../Blocks/Committees/CommitteeChair';
import { flattenToAppURL } from '@plone/volto/helpers';

const pathToLogo = '';
const Committee = (props) => {
  const { content } = props;
  console.log('props of committee: ', content);
  return (
    <>
      {content ? (
        <>
          <Helmet title={content.title} />
          <Container className="view-wrapper">
            <header>
              <h1 className="documentFirstHeading">{content.title}</h1>
              {content.description && (
                <p className="documentDescription">{content.description}</p>
              )}
            </header>
            <Header as="h2">Leadership</Header>
            <Grid.Row>
              <Item.Group>
                <Item>
                  <Item.Content>
                    {content.committee_chair_name && (
                      <CommitteeChair
                        chairPosition="chair"
                        chairPhoto={flattenToAppURL(
                          content.committee_chair_photo?.scales?.thumb
                            ?.download,
                        )}
                        contactButton={content?.committee_chair_vcard?.download}
                        chairTitle={content.committee_chair_title}
                        chairName={content.committee_chair_name}
                        chairFirm={content.committee_chair_firm}
                      />
                    )}
                    {content.committee_vice_chair_name && (
                      <CommitteeChair
                        chairPosition="vice chair"
                        chairPhoto={flattenToAppURL(
                          content.committee_vice_chair_photo?.scales?.thumb
                            ?.download,
                        )}
                        contactButton={
                          content?.committee_vice_chair_vcard?.download
                        }
                        chairTitle={content.committee_vice_chair_title}
                        chairName={content.committee_vice_chair_name}
                        chairFirm={content.committee_vice_chair_firm}
                        chairLocation={content.vice_chair_location}
                      />
                    )}
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Row>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Committee Resources</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      {content?.items.length ? (
                        content.items.map((item) => (
                          <div>
                            <a href={item.url}>
                              <span>
                                <Icon name="folder outline" /> {item.title}
                              </span>
                            </a>
                          </div>
                        ))
                      ) : (
                        <span>No results were found</span>
                      )}
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Recent Content</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      <p>No results were found</p>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </>
      ) : (
        <div />
      )}
    </>
  );
};

export default Committee;
