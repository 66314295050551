import React from 'react'

import PropTypes from 'prop-types'

import './component.css'



const Banner = (props) => {
    return (
        <div className="txbizLawCTA wrapper">
            <div className="txbizLawCTA_wrap" >
                <div className="maintext-container">
                    <h2>Exceptional Membership Value</h2>
                </div>
                <div className="subtext-container">
                    <h3>$30 a Year</h3>
                </div>
                <div className="button-container">
                    <a href="/help/join-the-section" type="button" className="button">
                        Join Now
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Banner;
