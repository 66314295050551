/**
 * Edit Resource Search block.
 * @module components/Blocks/ResourceSearch/Edit
 */

import React, { Component } from 'react';
import { SidebarPortal } from '@plone/volto/components';

import Data from './Data';
import './pheStyles.css';
import PeriodicHeaderElement from './PeriodicHeaderElement';

const Edit = (props) => {
  const { selected, onChangeBlock, block, data } = props;

  return (
    <div>
      <SidebarPortal selected={selected}>
        <Data
          {...props}
          block={block}
          data={data}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
      <div>Edit Mode: Period Page Header</div>
      <PeriodicHeaderElement data={data} />
    </div>
  );
};

export default Edit;

/**
 * Edit Alert block class.
 * @class Edit
 * @extends Component
 */
// class Edit extends Component {
//   /**
//    * Property types.
//    * @property {Object} propTypes Property types.
//    * @static
//    */
//   static propTypes = {
//     selected: PropTypes.bool.isRequired,
//     block: PropTypes.string.isRequired,
//     index: PropTypes.number.isRequired,
//     data: PropTypes.objectOf(PropTypes.any).isRequired,
//     content: PropTypes.objectOf(PropTypes.any).isRequired,
//     request: PropTypes.shape({
//       loading: PropTypes.bool,
//       loaded: PropTypes.bool,
//     }).isRequired,
//     pathname: PropTypes.string.isRequired,
//     onChangeBlock: PropTypes.func.isRequired,
//     onSelectBlock: PropTypes.func.isRequired,
//     onDeleteBlock: PropTypes.func.isRequired,
//     onFocusPreviousBlock: PropTypes.func.isRequired,
//     onFocusNextBlock: PropTypes.func.isRequired,
//     handleKeyDown: PropTypes.func.isRequired,
//     createContent: PropTypes.func.isRequired,
//   };
//
//   constructor(props) {
//     super(props);
//     this.blockNode = React.createRef();
//   }
//
//   render() {
//     const { selected, onChangeBlock, block, data } = this.props;
//     if (__SERVER__) {
//       return <div />;
//     }
//     return (
//       <div className="public-ui">
//         <SidebarPortal selected={selected}>
//           <Data
//             {...this.props}
//             data={data}
//             block={block}
//             onChangeBlock={onChangeBlock}
//           />
//         </SidebarPortal>
//         <div
//           className={cx('vertical-tab-menu-block', {
//             selected: this.props.selected,
//           })}
//         >
//           This is the menu block that will become a periodic page header
//         </div>
//       </div>
//     );
//   }
// }
//
// export default compose(
//   injectIntl,
//   connect(
//     (state) => ({
//       request: state.content.create,
//       content: state.content.data,
//     }),
//     { createContent },
//   ),
// )(Edit);
