import React from 'react';

import PropTypes from 'prop-types';

import './committee-chair.css';
import TxBizStar from './txbizlawstar.svg';

const CommitteeChair = (props) => {
  return (
    <div className="committee-chair-container">
      <div className="committee-chair-chairwrap">
        <div className="committee-chair-chaircontent">
          <div className="committee-chair-chairimagewrap">
            <img
              src={props.chairPhoto}
              alt={props.chairPhotoAltText}
              className="committee-chair-image"
            />
          </div>
          <div className="committee-chair-chairdetailswrap">
            <div className="committee-chair-chairdetails">
              <span className="committee-chair-position">
                {props.chairPosition}
              </span>
              <h1 className="committee-chair-title">{props.chairName}</h1>
              <span className="committee-chair-firm">{props.chairTitle}</span>
              <span className="committee-chair-firm">{props.chairFirm}</span>
              <span className="committee-chair-location">
                {props.chairLocation}
              </span>
            </div>
          </div>
        </div>
        <div className="committee-chair-chaircontact">
          <a href={props.contactButton}>
            <button type="button" className="committee-chair-button button">
              contact
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

CommitteeChair.defaultProps = {
  chairPhoto: TxBizStar,
  // 'https://play.teleporthq.io/static/svg/default-img.svg',
  chairPhotoAltText: 'image',
  chairFirm: 'something and partner',
  chairTitle: '',
  chairName: '',
  contactButton: 'contact',
  chairLocation: '',
};

CommitteeChair.propTypes = {
  chairPhoto: PropTypes.string,
  chairPhotoAltText: PropTypes.string,
  chairFirm: PropTypes.string,
  chairName: PropTypes.string,
  chairTitle: PropTypes.string,
  contactButton: PropTypes.string,
  chairLocation: PropTypes.string,
};

export default CommitteeChair;
