import React from 'react'

import PropTypes from 'prop-types'
import CTABanner from './CTABanner';

import './component.css'



const Edit = (props) => {
  return (
    <div class="edit-mode">
      <CTABanner props={props} />
    </div>
    
  )
};

export default Edit;
