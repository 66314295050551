import { Container, Grid, Card, Image,  
  Item,
  ItemMeta,
  ItemImage,
  ItemHeader,
  ItemGroup,
  ItemExtra,
  ItemDescription,
  ItemContent } from 'semantic-ui-react';
import Moment from 'moment';
import { UniversalLink, Icon, Component } from '@plone/volto/components';
import ArrowRightSVG from '@plone/volto/icons/ahead.svg';
import "./component.css";

const ListingGridVariation = (props) => {
  const { items } = props;

  return (
    <div className="listing-grid">
      <Container>
        <Grid stackable columns={3}>
          <Grid.Row>
            {items.map((item) => (
              
            
              <Grid.Column>
                <ItemGroup>
                <Item as='a' href={item['@id']}>
                <Component className="preview-image" componentName="PreviewImage" item={item} alt="" />
              <ItemContent>
              <ItemMeta>{item.title}</ItemMeta>
                <ItemDescription>
                {item.description}
                </ItemDescription>
              </ItemContent>
            </Item>

                </ItemGroup>
                  
            
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default ListingGridVariation;