/**
 * @description New page header component based on design
 */
import React from 'react';
import PropTypes from 'prop-types';

import PeriodicHeaderElement from './PeriodicHeaderElement';

/**
 * @function View
 * @param {Object} data
 * @param {string} path
 * @returns {Element}
 * @constructor
 */
const View = ({ data, path }) => {
  return <PeriodicHeaderElement data={data} />;
};

View.PropTypes = {
  data: PropTypes.object,
};
// default export
export default React.memo(View);
