import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const schema = {
  title: 'URL',
  fieldsets: [
    {
      id: 'default',
      title: 'default',
      fields: ['url', 'description'],
    },
  ],
  properties: {
    url: {
      title: 'URL',
      widget: 'url',
    },
    description: {
      title: 'Description',
      widget: 'textarea',
    },
  },
  required: [],
};

const Widget = (props) => <ObjectListWidget {...props} schema={schema} />;

export default Widget;
