import { Container, Grid, Label, Card, Image,  
  Item,
  ItemHeader,
  ItemGroup,
  Icon,
  ItemDescription,
  ItemContent } from 'semantic-ui-react';
import Moment from 'moment';
import { UniversalLink, Component } from '@plone/volto/components';
import ArrowRightSVG from '@plone/volto/icons/ahead.svg';
import "./component.css";

const GatedContentListVariation = (props) => {
  const { items } = props;

  return (
    <div className="listing-grid">
      <Container>
        <Grid stackable columns={1} padded>
          <Grid.Row className="gated-content-list">
            {items.map((item) => {
              const isMemberOnly = item.review_state == 'internally_published';
            
            return (
            
              <Grid.Column>
                <ItemGroup>
                <Item as='a' href={item['@id']}>
              <ItemContent>
              <ItemHeader>{item.title} 
               {isMemberOnly && <Label pointing='left'>members only</Label>} {/* Render the label if the item is private */}
              </ItemHeader>
                <ItemDescription>
                {item.description}
                </ItemDescription>
              </ItemContent>
            </Item>

                </ItemGroup>
                  
            
              </Grid.Column>
            );
          
 } )}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default GatedContentListVariation;