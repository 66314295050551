import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { getBaseUrl } from '@plone/volto/helpers';
import AccessDenied from './AccessDenied/AccessDenied';

const Unauthorized = () => {
  const error_message = useSelector((state) => state.apierror?.message);
  let location = useLocation();

  return (
    <Container className="unauthorized-view-wrapper">
      <AccessDenied
        loginUrl={`${getBaseUrl(location.pathname)}/login`}
        joinUrl="/join"
        message={"hey"} />
      <p style={{padding:2 + 'rem'}}>
        <FormattedMessage
          id="If ou have the correct web address but are encountering an error, please contact the {site_admin}."
          defaultMessage="If you are sure that you are encountering an error, please {contact_the_site_admin}."
          values={{
            contact_the_site_admin: (
              <Link to="/contact-form">
                <FormattedMessage
                  id="Site Administration"
                  defaultMessage="contact the Site Administration"
                />
              </Link>
            ),
          }}
        />
      </p>
    </Container>
  );
};

export default withServerErrorCode(401)(Unauthorized);
